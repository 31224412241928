.caption>h1{
    font-size: 3.7rem;
    font-weight: bold;
    z-index: 100;
    position: relative;
}
.top{
    z-index: 100;
    position: relative;
}
.overlay{
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: 0.61;
}
.slide-container{
    padding: 7% 0;
}
.fit-h{
    height: 30vh !important;
}

@media only screen and (max-width: 600px) {
    .zindex-modal{
        font-size: 1.5rem !important;
    }
}
@media only screen and (min-width: 992px) {
    .zindex-modal{
        font-size: 2.7rem !important;
    }
}