
@media only screen and (max-width: 600px) {
    .card{
        width: 100%;
        margin-bottom: 20px;
    }
    .note{
        font-size: 1.2rem;
    }
}
