.big-text{
    font-size: 3rem;
    width: 60%;
    margin: auto;
}
.b-bottom{
    padding-bottom: 15px;
    margin-bottom: 5px;
    border-bottom: 1px solid #fff;
}
.anchor:hover{
    color: #8F4E9A !important;
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    .big-text{
        font-size: 1.2rem;
    }
    .small{
        font-size: .7rem;
    }
    .bottom{
        display: flex;
        flex-direction: column;
    }
    .btm{
        width: 80%;
        margin: 10px 0;
    }
}