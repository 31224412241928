.child{
    border-radius: 5px;
    height: 25vh;
}
.icon{
    font-size: 1.5rem;
    display: none;
}
@media only screen and (max-width: 600px) {
    .pd{
        display: flex;
        flex-direction: column;
        align-items: start;
        text-align: start;
        justify-content: flex-start !important;
        margin-left: 0 !important;
    }
    .ms-5{
        margin-left: 0 !important;
    }
    .child{
        width: 100%;
    }
    .story{
        margin-top: 10px !important;
    }
    .cd{
        width: 100%;
    }
    .icon{
        font-size: 1.5rem;
        display: block;
    }
}