.image{
    width: 40%;
}
.bty{
    width: 80%;
    margin-top: 10px;
    padding: 20px;
}


@media only screen and (max-width: 600px) {
    p{
        text-align: justify;
        font-size: .8rem;
    }
    .image{
        width: 100%;
    }
    .bty{
        width: 100%;
        margin-top: 10px;
        padding: 0;
        h1{
            font-size: 1.5em;
        }
    }
}