.title{
    font-size: 2.6rem;
}
.bx{
    width: 33%;
}
@media only screen and (max-width: 600px) {
    .title{
        font-size: 2rem;
    }
    .support{
        padding: 10px 10px !important;
    }
    .large-text{
        font-size: 1.5rem;
    }
    .sp-box{
        margin: 0 !important;
        padding: 0 !important;
    }
    .ptm{
        width: 80px;
    }
    .bx{
        padding: 0;
        margin: 0;
        width: 100%;
    }

}