.canvas{
    display: none !important;
}
@media only screen and (max-width: 600px) {
    .socials{
        width: 20% !important;
    }
    .logo{
        width:150px;
    }
    .donate-btn{
        margin-top: 20px;
    }
    .canvas{
        display: flex !important;
    }
    .hide-sm{
        display: none !important;
    }
}