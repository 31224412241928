.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #8F4E9A; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.full{
    height: 50vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F5F5;
    flex-direction: column;
}
@media only screen and (max-width: 600px) {
    .form{
        width: 80% !important;
    }
}