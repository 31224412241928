$primary: #C51DE1;
$body-bg: #fff;
$secondary: #8F4E9A;
$tertiary: #EFEEEA;


@import "bootstrap/scss/bootstrap";
@import 'react-slideshow-image/dist/styles.css';

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.gradient{
    background: linear-gradient(to bottom, #8F4E9A,#94549f, #a074a7);
}
.text-light{
    color: rgb(164, 161, 161);
}
a{
    color:#fff;
    text-decoration: none;
    transition: all ease-out 2;
}
a:hover{
    color: $primary;
    // transform: scale(1.5,1.5);
}
nav>ul>li>a:hover{
    color:$primary;
}
ul{
    list-style: none;
}
li>a{
    color:black;
}
.active{
    color: $primary;
}
.small-text{
    font-size: .8em;
}
.large-text{
    font-size: 5rem;
}
.w-7{
    width: 7%;
}
.w-10{
    width: 10%;
}
.w-15{
    width: 15%;
}
.w-20{
    width: 20%;
}
.w-30{
    width: 30%;
}
.w-40{
    width: 40%;
}
.w-70{
    width: 70%;
}
.w-80{
    width: 80%;
}
.h-25{
    height: 25vh;
}
.h-50{
    height: 50vh;
}
.dots{
    position: absolute;
    bottom: 30px;
    background: red;
}
.donate-btn{
    transition: all ease-in-out 5s;
}
.donate-btn:hover{
    background: $primary;
    transform: scale(1.1,1.1);
}
.cursor{
    cursor: pointer;
}
.card{
    box-shadow: 1px 2px 4px #888888;
}
.card:hover{
    transform: scale(1.01,1.01);
}
.card-body{
    transition: all ease-in-out .5s;
}
.card-body:hover{
    background: rgba(66, 32, 63, 0.5);
    color: white;
    font-weight: bold;
}
.bg-tertiary{
    background: $tertiary;
}
navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
    color: $primary;
}